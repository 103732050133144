/* import __COLOCATED_TEMPLATE__ from './block-group-composer.hbs'; */
/* RESPONSIBLE TEAM: team-conversational-knowledge */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { type Block } from '@intercom/interblocks.ts';
import { type BlockGroup } from '../shared/suggestion-editor';
import { BlocksDocument, type ComposerPublicAPI } from '@intercom/embercom-prosemirror-composer';
import { EntityType } from 'embercom/models/data/entity-types';
import type IntlService from 'ember-intl/services/intl';
import ArticlesComposerConfig from 'embercom/lib/articles/composer-config';
import type Model from '@ember-data/model';
import { type LocalizedKnowledgeContent } from 'embercom/objects/knowledge-hub/localized-knowledge-content';
import type ArticleContent from 'embercom/models/articles/article-content';
import {
  copyHeadingLinkToClipboard,
  getSelectionForAI,
} from 'embercom/helpers/articles/editor-helper';
import type ClipboardService from 'embercom/services/clipboard-service';
import ENV from 'embercom/config/environment';
import { ContentSnippetComposerConfig } from 'embercom/components/content-service/composer/content-snippet-editor';
import { Status } from 'embercom/models/data/content-service/content-review-statuses';
import type Composer from '@intercom/embercom-prosemirror-composer/lib/composer';
import type SuggestionService from 'embercom/services/suggestion-service';

interface Signature {
  Element: HTMLSpanElement;
  Args: {
    blockGroup: BlockGroup;
    contentEntityType: EntityType;
    activeContent: LocalizedKnowledgeContent & Model;
    setFocusedComposer: (composer?: Composer) => void;
    blockGroupIndex: number;
  };
}

export default class BlockGroupComposer extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare notificationsService: $TSFixMe;
  @service declare clipboardService: ClipboardService;
  @service declare suggestionService: SuggestionService;
  get composerConfig() {
    if (this.args.contentEntityType === EntityType.ContentSnippet) {
      return new ContentSnippetComposerConfig({
        eventService: this.intercomEventService,
        intl: this.intl,
        placeholder: this.intl.t('ai-content-library.content-snippet.editor.placeholder'),
      });
    }
    return this.articleComposerConfig;
  }

  get articleContent() {
    return this.args.activeContent as unknown as ArticleContent;
  }

  get articleComposerConfig() {
    let eventService = this.intercomEventService;
    let analytics = {
      trackAnalyticsEvent: (eventName: any, attrs: any) => {
        eventService.trackAnalyticsEvent({
          action: eventName,
          object: 'article',
          place: 'composer',
          ...attrs,
        });
      },
    };

    let anchorLinkOnClick = (heading: any) => {
      copyHeadingLinkToClipboard(
        heading,
        this.articleContent,
        this.notificationsService,
        this.intl,
        this.clipboardService,
        this.intercomEventService,
        this.articleContent.locale ?? '',
      );
    };

    let config = new ArticlesComposerConfig(
      this.appService.app,
      this.articleContent.textDirection,
      analytics,
      anchorLinkOnClick,
      true,
      false,
    );

    if (this.appService.app.canUseArticlesAi) {
      config.formatters = {
        placement: 'right',
        config: [{ icon: 'ai', key: 'ai-expand' }].map(({ icon, key }) => ({
          icon,
          name: key,
          transform: async (selectedBlocks: any) => {
            this.notificationsService.notify(
              this.intl.t('articles.editor.ai-running'),
              ENV.APP._3000MS,
            );
            let blocks = await getSelectionForAI(
              selectedBlocks,
              this.appService.app,
              this.articleContent.id,
              this.notificationsService,
            );
            return blocks;
          },
          description: this.intl.t(`articles.editor.${key}`),
        })),
      };
    }

    return config;
  }

  get blocks() {
    if (this.args.blockGroup.reviewRequest?.get('status') === Status.Rejected) {
      return this.args.blockGroup.blocksBefore ?? [];
    }
    return this.args.blockGroup.blocks ?? [];
  }

  set blocks(value: Block[]) {
    if (this.args.blockGroup.reviewRequest?.get('status') === Status.Rejected) {
      this.args.blockGroup.blocksBefore = value;
    } else {
      this.args.blockGroup.blocks = value;
    }
  }

  get blocksDocument() {
    return new BlocksDocument(this.blocks);
  }

  @action
  composerChanged(newBlocksDocument: BlocksDocument) {
    this.blocks = newBlocksDocument.blocks;
    if (!this.suggestionService.hasDirtyContent) {
      this.suggestionService.hasDirtyContent = true;
    }
  }

  @action
  handleComposerReady(composerApi: ComposerPublicAPI) {
    this.args.blockGroup.composerApi = composerApi;
    this.setFocusedComposer();
  }

  @action
  handleComposerFocus() {
    this.setFocusedComposer();
  }

  @action
  setFocusedComposer() {
    if (this.args.contentEntityType === EntityType.ArticleContent) {
      this.args.setFocusedComposer(this.args.blockGroup.composerApi?.composer);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::ContentReview::BlockGroupComposer': typeof BlockGroupComposer;
    'knowledge-hub/content-editor/content-review/block-group-composer': typeof BlockGroupComposer;
  }
}
